<template>
  <div>

    <svg version="1.1" id="svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter id="filter" width="100%" height="150%" x="0%" y="-0%">    
        <feTurbulence id="turbulence" result="TURBULENCE" baseFrequency="0.05" numOctaves="8" seed="20" xChannelSelector="A" yChannelSelector="G" />
        <feDisplacementMap in="SourceGraphic" in2="TURBULENCE" result="DISPLACEMENT" scale="35" />
        </filter>
      </defs>
    </svg>

    <div class="page-container">

      <span class="title-header">
        Collections :
        <span class="title-count">(pour le moment les clés sont envoyé manuellement attendez la fin du stream)</span>
      </span>

      <div class="cards-list">


        <div class="card-container" :class="{ gold: card.gold }" v-for="card in cards" :key="card.id" :style="`background-image: url(/background/${card.background}.png)`">
          
          <!-- {{ card.id }} -->

          <!-- <div class="layer-particules"></div> -->
          <div class="layer-energy" v-if="card.gold"></div>
          <div class="layer-light" v-if="card.gold"></div>

          <div class="card-layer-art" :style="`background-image: url(/cards/${card.gender}/${card.type}.png)`"/>
          <!-- <div class="card-layer-background" /> -->


        </div>
        

      </div>

    </div>
    
  </div>
</template>

<style lang="scss" scoped>

.page-container {
  max-width: 1500px;
  margin: 0 auto;

  padding-top: 32px;
}

.title-header {
  text-transform: uppercase;
  letter-spacing: 1.2px;

  color: white;

  display: flex;
  align-items: center;

  .title-count {
    color: #B9BBBE;

    margin-left: 4px;
    font-size: 12px;

    margin-bottom: -2px;
  }
}

.cards-list {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
}

.card-container {
  display: flex;

  position: relative;

  background-color: red;
  border-radius: 10%;

  height: 312px;
  width: 312px;

  justify-content: center;
  align-items: flex-end;

  margin: 32px;

  box-shadow: 0 0 0 6px white;

  &.gold {
    box-shadow: 0 0 0 6px #FFDD28;
  }

  background-size: contain;

  overflow: hidden;
  background-position: center;

  background-size: 100%;

  cursor: pointer;

  transition: all ease 0.3s;

  &:hover {
    background-size: 105%;
    transform: scale(1.025);
    // box-shadow: 0 0 0 3px white, #8fd9f8 0px 0px 0px 6px;
  }

  @keyframes beams-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      opacity: .1;
    }
    15% {
      opacity: .05;
    }
    35% {
      opacity: .5;
    }
    40% {
      opacity: .05;
    }
    60% {
      opacity: .65;
    }
    70% {
      opacity: .1;
    }
    80% {
      opacity: .6;
    }
    92% {
      opacity: .1;
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      opacity: .05;
    }
  }


  @keyframes particle-rise {
    0% {
      -webkit-transform: rotate(8deg) translateY(0%);
              transform: rotate(8deg) translateY(0%);
    }
    100% {
      -webkit-transform: rotate(8deg) translateY(-50%);
              transform: rotate(8deg) translateY(-50%);
    }
  }

  @keyframes energy-rise {
  0% {
    -webkit-transform: rotate(8deg) translateY(0%);
            transform: rotate(8deg) translateY(0%);
  }
  50% {
    -webkit-transform: rotate(8deg) translateY(-50%);
            transform: rotate(8deg) translateY(-50%);
  }
  100% {
    -webkit-transform: rotate(8deg) translateY(0%);
            transform: rotate(8deg) translateY(0%);
  }
}

  .layer-energy {
    top: -3%;
    left: -6%;
    width: 112%;
    height: 122%;

    position: absolute;

    mask-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/436243/lyra-energy-mask-6.png);
    mask-mode: luminance;
    mask-size: 100% 100%;
    filter: url(#filter);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200%;
      background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/436243/energy-waves-93.png) 0% 0% repeat;
      -webkit-animation: energy-rise 15s linear infinite;
      animation: energy-rise 15s linear infinite;
    }
  }

  .layer-light {

    top: -3%;
    left: -6%;
    width: 112%;
    height: 122%;
    position: absolute;

    mask-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/436243/lyra-beams-mask-5.png);
    mask-size: 100% 100%;

    &::after {
      content: '';
      position: absolute;
      top: -2%;
      left: 8%;
      width: 118%;
      height: 92%;
      background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/436243/beams-4.png) 0% 0% no-repeat;
      background-size: cover;
      background-position: center;
      animation: beams-spin 20s linear infinite;
      mix-blend-mode: multiply;
    }
  }

  .layer-particules {
    top: -3%;
    left: -6%;
    width: 112%;
    height: 122%;
    position: absolute;

    mask-size: 100% 100%;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200%;
      background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/436243/ember-particles.png) 0% 0% repeat;
      background-size: 100%;
      animation: particle-rise 15s linear infinite;
    }
  }

  .card-layer-art {
    margin-bottom: 32px;

    height: 184px;
    width: 184px;

    filter: drop-shadow(0 19px 38px rgba(0,0,0,0.30)) drop-shadow(0 15px 12px rgba(0,0,0,0.22));

    background-size: contain;
  }
}

</style>

<script>

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default {

  name: 'Collection',

  data () {
    return {

      cards: [
        {
          id: uuidv4(),
          gender: 'panda',
          type: 'kiss',
          background: 'forest',
          gold: true
        },
        {
          id: uuidv4(),
          gender: 'panda',
          type: 'kiss',
          background: 'forest2',
          gold: true
        }
      ]

    }
  }

}
</script>
